:root {
    --transparentBlack: #000000ab;
    --white: #fff;
  }

.bg-primary{
  background-color: #0f1134 !important;
}

.bg-secondary{
  background-color: #32c10d !important;
}

.bg-blue {
  background-color: #0a284d !important;
}

.text-primary{
  color:#111344 !important;
}

.text-secondary{
  color: #04cc21 !important;
}

body{
  background: #f1f1f180 !important;
}

h1{
  font-size: 2rem;
}

h5{
  font-size: 1em;
}

.text-info{
  color: #878787 !important;
}


.btn-link{
  color: #227fbb;
  text-decoration: none;
  font-weight: 600;
}
.btn-link:hover{
  color: #111344;
}

.btn-primary{
  background-color: #0d386b !important;
  border:none;
}
.btn-primary:hover{
  background-color: #0a284d !important;
}

.btn-outline-primary{
  border:1px solid #0a284d;
  color: #0a284d;
}
.btn-outline-primary:hover{
  background-color: #0a284d;
  color:white;
}