.menuLeftCol{
  background:#0f1134;
  width: 301px;
  color: #ffffff;
  height: 92vh;
  opacity: 1;
  transition: all 200ms ease-in-out;
  box-shadow: 2px 0 5px -2px #3e3e3e57;
  position: sticky;
  top: 0;
}

.menuLeftCol a {
  color: #000;
  display: block;
  text-decoration: none;
  padding-left: 9px;
  padding-bottom: 3px;
}

.closeMenu{
  width: 0px;
  min-width: 0 !important;
  padding: 0 !important;
  opacity: 0;
}

.list{
  list-style: none;
  padding: 0;
  max-height: 300px;
  overflow-y: scroll;
}

.menuTitle{
  margin: 0.5rem 0;
  color: #a3a2a2;
}

.menuLeftCol.darkmode {
  background-color: #000;
}



@media screen and (max-width: 600px) {
  .menuLeftCol{
    position: absolute;
    z-index: 9;
  }
}