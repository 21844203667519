.AnalogInputBox{
  border: 2px solid #0000000a;
  border-radius: 15px;
  width: 160px;
  height: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.rcp, 
.rcp svg:first-child
{
  width: 125px !important;
}

.indicator{
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon{
  color: #e6e6e6;
  position: absolute;
  top: 1px;
  right: 2px;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.icon:hover{
  color: #04cc21;
}

.isWatched{
  color: #04cc21 !important;
}

.errorContainer{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorContainer span{
  color:#bf1a1a;
}