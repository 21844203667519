

.container{
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8eaed;
  
}

.formContainer{
  background-color: #fff;
  padding: 2rem 4rem;
  -webkit-box-shadow: 0px 1px 3px 1px rgba(60,64,67,0.15); 
  box-shadow: 0px 1px 4px 1px rgba(60,64,67,0.15);
  border-radius: 30px;
  margin-top: 35px;
}

.inputContainer{
  color: #0a0b2a;
  font-size: 20px;
}

.inputContainer input{
  background-color: transparent;
  border: 1px solid #d3d3d3;
  color: #0a0b2a;
  padding: 0.3rem 0rem 0.3rem 1rem;
  border-radius: 3px;
}

.header_img{
  width: 100%;
  max-width: 300px;
}