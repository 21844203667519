:root {
  --transparentBlack: #000000ab;
  --white: #fff;
}

.fixedContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--transparentBlack);
  z-index: 9;
}

.alertContainer{
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 92%;
  border-radius: 9px;
  max-width: 500px;
  max-height: 96vh;
  overflow-y: scroll;
}

/* .alertContainer > div {
  padding: 1rem 0;
  border-bottom: 1px solid rgb(60 64 67 / 15%);
} */

/* .settingsInputsWrapper{
  width: 92%;
  max-width: 700px;
} */

.noUnderline{
  text-decoration: none;
}

.greyContainer{
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8eaed;
}

.settingsCard{
  background-color: #fff;
  box-shadow: 0px 1px 4px 1px rgb(60 64 67 / 15%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

/* .settingsCard > div{
  padding: 1rem 0;
  border-bottom: 1px solid rgb(60 64 67 / 15%);
} */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.settingsCard select, .settingsCard input[type=text], .settingsCard input[type=number] {
  height: 35px;
  border-radius: 8px;
  padding: 0px 8px;
  border-color: rgb(60 64 67 / 15%);
  border-width: 0.5px;
  cursor: pointer;
}

.settingsCard  input[type=text]{
  width: 170px;
}

.settingsCard input[type=number]{
  width: 60px;
}

.settingsCard input[type=checkbox] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.registerForm{
  background-color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  row-gap: 1rem;
  border-radius: 7px;
}

.registerForm input[type="text"],.registerForm input[type="password"],.registerForm input[type="email"], .registerForm select{
  padding: 9px 8px;
  border: 1px solid #d3d3d3;
  color: #0a0b2a;
  width: 100%;
  border-radius: 8px;
}

.registerImage{
  max-width: 300px;
  width: 90%;
  object-fit: cover;
}

.blueButton{
  width: 100%;
  background-color: #227fbb;
  color: #fff;
  border: 2px solid #227fbb;
  padding: 1rem 1rem;
  font-size: 19px;
  font-weight: 500;
  transition: all 200ms ease-in-out;
}

.blueButton:hover{
  background-color: #fff;
  color: #227fbb;
}

.boxShadow {
  -webkit-box-shadow: 0px 1px 3px 1px rgba(60,64,67,0.15); 
  box-shadow: 0px 1px 4px 1px rgba(60,64,67,0.15);
}


.addDeviceContainer{
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 7px;
}

.addDeviceContainer input[type="text"], .addDeviceContainer input[type="password"] {
  padding: 9px 8px;
  border: 1px solid #464a6a;
  width: 100%;
  border-radius: 9px;
}

.notificationsContainer{
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111344;
  border-right: 1px solid #d5d5d5 !important;
  border-left: 1px solid #d5d5d5 !important;
}

.notificationsAbsoluteCounter{
  font-size: 16px;
  font-weight: 700;
}

.notificationsContainer:hover .notificationsShowAbsoluteCounter{
  display: block !important;
}

.notificationsShowAbsoluteCounter{
  position: absolute;
  width: 290px;
  height: 250px;
  background-color: #fff;
  top: 85%;
  z-index: 100;
  left: -194px ;
  overflow-y: scroll;
  overflow-x: hidden;
  display: none;
  border-radius: 5px;
}


.notificationItem{
  white-space: normal;
  border-top: 1px solid #eee;
  padding: 10px 20px !important;
}

.notificationItemTitle{
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}
.notificationItemMessage{
  font-size: 13px;
}
.notificationDate{
  font-size: 11px;
  font-weight: 500;
}

.notificationRead{
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: end;
  gap: 0.5rem;
  
}

.cardContainer{
  background-color: #fff;
  margin: 1rem 0;
  border-radius: 8px;
  min-width: 300px;
  -webkit-box-shadow: 0px 1px 3px 1px rgba(60,64,67,0.15); 
  box-shadow: 0px 1px 4px 1px rgba(60,64,67,0.15);
}

.cardTitleColor{
  background: #0d386b;
  color: #ffffff;
}

.counterContainer{
  display: flex;
  flex: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
}


.iconContainer{
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}


.menuActiveLink{
  background-color: #04cc21;
  color: #fff !important;
}

.header_img{
  height: 49px;
  width: auto;
  object-fit: cover;
}

.header_container{
  background-color: #fff;   
  border-bottom: 1px solid #d5d5d5de;
  color: #ffffff;
  padding: 8px 20px;
  box-shadow: 1px 0px 16px 4px #dddddd5c;
}

.user{
  position: relative;
  padding: 0;
}

.user > a{
  color:#000;
}

.menuToggler{
  background-color: transparent;
  border: 1px solid #ddd;
  padding:3px 12px;
  border-radius: 8px;
  margin-left: 15px;
  color: #227fbb;
}


@media screen and (min-width: 767px ) {
  .menuToggler{
    display: none;
  }

  .header_user_info{
    display: block !important;
  }
}

.header_user_info{
  display: none;
}

.ulColumn{
  list-style: none;
  padding: 0;
  margin: 0;
}

.ulColumn li{
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0;
  border-top: 1px solid #bebebe;
}

.ulColumn li:first-child{
  border-top: none;
}

.deviceContainer{
  margin:0 auto;
  width: 100%;
  color: #000;
  overflow-x: hidden;  
}

.watchListRelative:hover .watchListAbsolute{
  display: block;
}

.watchListAbsolute{
  border: 1px solid #ddd;
  position: absolute;
  min-width: 250px;
  top: 34px;
  right: 0;
  z-index: 9;
  display: none;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}


.profileContainer{
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 92%;
  border-radius: 9px;
  max-width: 500px;
  max-height: 96vh;
  overflow-y: scroll;
}

.profileContainer > div {
  padding: 1rem 0;
  border-bottom: 1px solid rgb(60 64 67 / 15%);
}

.profileContainer input[type="text"], .profileContainer input[type="number"],.profileContainer select{
  width: 220px;
  height: 35px;
  border-radius: 30px;
  padding: 3px 9px;
  border-color: rgb(60 64 67 / 15%);
  border-width: 0.5px;
  cursor: pointer;
}

.deviceSettingsContainer{
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 92%;
  border-radius: 9px;
  max-width: 500px;
  max-height: 96vh;
  overflow-y: scroll;
}

.deviceSettingsContainer > div {
  padding: 1rem 0;
  border-bottom: 1px solid rgb(60 64 67 / 15%);
}

.deviceSettingsContainer input[type="text"], .deviceSettingsContainer input[type="number"]{
  width: 220px;
  height: 35px;
  border-radius: 30px;
  padding: 3px 9px;
  border-color: rgb(60 64 67 / 15%);
  border-width: 0.5px;
  cursor: pointer;
}

.radius-15{
  border-radius: 15px;
}

.radius-top-left-right-15{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.radius-top-left-right-8{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.top-divider{
  border-top:1px solid #d5d5d53e;
}
.bottom-divider{
  border-bottom:1px solid #d5d5d53e;
}
.top-divider-strong{
  border-top:1px solid #b9b9b962;
}
.bottom-divider-strong{
  border-bottom:1px solid #b9b9b962;
}

.bg-seethrought-grey{
  background-color: #0000007a;
}
.z-index-2{
  z-index: 2;
}

.border-radius-bottom-8{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.printList{
  padding: 1rem;
  list-style: none;
  max-height: 400px;
  overflow-y: scroll;
}

.printList li{
  padding: 0.5rem 1rem;
  border: 1px solid;
  display: flex;
  justify-content: space-around;
  border-radius: 4px;
  border: 1px solid #d3d6d7;
  -webkit-box-shadow: 0px 1px 3px 1px rgba(60,64,67,0.15); 
  box-shadow: 0px 1px 4px 1px rgba(60,64,67,0.15);
  margin-bottom: 10px;
}

.overflowingEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.printList::-webkit-scrollbar{width:6px;border-left:1px solid #c8c8c8;}
.printList::-webkit-scrollbar-thumb{background-color:#000;border-radius: 20px;}
::-webkit-scrollbar{width:6px;border-left:1px solid #c8c8c8;}
::-webkit-scrollbar-thumb{background-color:#000;border-radius: 20px;}

.custom-btn-blue{
  width: 100%;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 19px;
  font-weight: 500;
  transition: all 200ms ease-in-out;
  border-radius: 15px;
  background-color: #227fbb;
  border: 2px solid #227fbb;
}

.custom-btn-blue:hover{
  background-color: #fff;
  color: #227fbb;
}

.tinyText{
  font-size: 12px;
  font-weight: 700;
  color:#959595;
  position: absolute;
  bottom: 13px;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  padding-right: 18px;
}


.generalPopupWrapperFixed{
  position: fixed;
  z-index: 5000;
  background: #6767679c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.generalPopupWrapperContainer{
  padding: 13px 10px 13px 16px;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  position: relative;
  min-width: 300px;
  min-height: 200px;
  max-height: 650px;
}

.generalPopupWrapperContainer::-webkit-scrollbar {
  width: 6px;
}

.generalPopupWrapperContainer::-webkit-scrollbar-track {
  background: transparent;
}

.generalPopupWrapperContainer::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 15px;
}

.generalPopupWrapperContainer::-webkit-scrollbar-thumb:hover {
  background: #1e1e1e;
}

.enable{
  color: #04cc21;
}

.disable{
  color: #555;
}

.sensor_disabled_abs{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0003;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.abs_date_picker{
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 1px 4px 1px rgba(60,64,67,0.15);
  border-radius: 8px;
}

