.status{
  color: white;
  border-radius: 18px;
  padding: 0.2rem 1rem;
  font-size: 18px;
  font-weight: 700;
}

.offline{
  background: #bf1a1a;
}

.online{
  background-color: #04cc21;
}

.buttons{
  background-color: transparent;
  border: 2px solid  #a4a4a4;
  color: #767676;
  border-radius: 4px;
  padding: 4px 15px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons:hover{
  color: #04cc21;
}